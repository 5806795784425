import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer"
import videoHero360p from "../../videos//hero-360p.mp4"
import videoHero540p from "../../videos//hero-540p.mp4"
import videoHero720p from "../../videos//hero-720p.mp4"
import videoHero1080p from "../../videos//hero-1080p.mp4"
import posterHero360p from "../../videos//hero-poster-360p.jpg"
import posterHero540p from "../../videos//hero-poster-540p.jpg"
import posterHero720p from "../../videos//hero-poster-720p.jpg"
import posterHero1080p from "../../videos//hero-poster-1080p.jpg"
import ResponsiveVideo from "../responsive-video"
import DoubleArrowIcon from "../../svg/inline/double-arrow.svg"
import Logo from "../../svg/inline/logo.svg"
import BuyButton from "../buy-button"
import { useWindowSize } from "@react-hook/window-size"
import OneIcon from "../../svg/inline/one.svg"
import FourIcon from "../../svg/inline/four.svg"
import FiveIcon from "../../svg/inline/five.svg"
import { useIntl } from "gatsby-plugin-react-intl"
import nl2br from "react-nl2br"
import ARIcon from "../../svg/inline/ar.svg"
import Modal from "../ar-viewer/modal"
import { isChrome, isIOS } from "react-device-detect"
import { StaticImage } from "gatsby-plugin-image"

gsap.registerPlugin(ScrollTrigger)

const aggregateTextFrames = (textNodes: NodeListOf<HTMLDivElement>) => {
  const textFrames: Array<{
    start: number
    end: number
    el: HTMLDivElement
  }> = []

  textNodes.forEach(el => {
    const framesAttr = el.getAttribute("data-frame")

    if (!framesAttr) {
      return
    }

    const [start, end] = framesAttr.split(":").map(stringNum => {
      const num = parseInt(stringNum, 10)

      if (isNaN(num)) {
        throw new Error(`Could not parse frames number ${stringNum}`)
      }

      return num
    })

    if (start < 0 || end < 0 || start >= end) {
      throw new Error(`Invalid text frames config given: ${framesAttr}`)
    }

    textFrames.push({ start, end, el })
  })

  return textFrames
}

const ScrollingArea: React.FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const videoContainerRef = useRef<HTMLDivElement>(null)
  const animationContainerRef = useRef<HTMLDivElement>(null)
  const canvasContainerRef = useRef<HTMLCanvasElement>(null)
  const animationTextContainerRef = useRef<HTMLDivElement>(null)
  const modelRef = useRef<ModelViewerElement & HTMLElement>(null)
  const [modelViewerLoaded, setModelViewerLoaded] = useState(false)
  const [canActivateAR, setCanActivateAR] = useState<boolean | null>(null)
  const [showARModal, setShowARModal] = useState(false)
  const [windowWidth, windowHeight] = useWindowSize()

  const lastUpdateAtFrame = useRef(0)

  const useMobileSprites = windowWidth <= 1080
  const imagesPerSprite = 90
  const numImages = 703 - 1 // -1 since this is 0 indexed
  const numSprites = Math.ceil(numImages / imagesPerSprite)
  const imageWidth = useMobileSprites ? 720 : 1280
  const imageHeight = (useMobileSprites ? 405 : 720) * numImages
  const hDiff = imageWidth
  const vDiff = imageHeight / numImages

  const intl = useIntl()

  const fadeClassName =
    "opacity-0 transition-opacity duration-[700ms] ease-in-out"

  const toggleARModal = () => setShowARModal(!showARModal)

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    const videoContainer = videoContainerRef.current
    const animationContainer = animationContainerRef.current
    const canvasContainer = canvasContainerRef.current
    const animationTextContainer = animationTextContainerRef.current

    document.documentElement.style.setProperty(
      "--onefourfive-1vh",
      `${windowHeight / 100}px`
    )

    if (
      !scrollContainer ||
      !videoContainer ||
      !animationContainer ||
      !canvasContainer ||
      !animationTextContainer
    ) {
      return
    }

    const ctx = canvasContainer.getContext("2d")

    if (!ctx) {
      return
    }

    canvasContainer.width = hDiff
    canvasContainer.height = vDiff

    const animationContainerFrameCounter = { num: 0 }
    const images = Array.from({ length: numSprites }, (x, i) => {
      const image = new Image()
      image.src = `/sprites/sprite${useMobileSprites ? "-mobile" : ""}-${
        i + 1
      }.jpg`

      if (i === 0) {
        image.onload = () => onUpdate()
      }

      return image
    })

    function onUpdate() {
      const currentFrame = Math.round(animationContainerFrameCounter.num)

      if (currentFrame === lastUpdateAtFrame.current) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.clearRect(0, 0, hDiff, vDiff)

      const imageNum = Math.floor(currentFrame / imagesPerSprite)
      const y = currentFrame * vDiff - imageNum * vDiff * imagesPerSprite

      textFrames.forEach(textFrame => {
        if (currentFrame >= textFrame.start && currentFrame <= textFrame.end) {
          textFrame.el.classList.remove("opacity-0", "pointer-events-none")
          textFrame.el.classList.add("opacity-100", "pointer-events-auto")
        } else {
          textFrame.el.classList.add("opacity-0", "pointer-events-none")
          textFrame.el.classList.remove("opacity-100", "pointer-events-auto")
        }
      })

      lastUpdateAtFrame.current = currentFrame

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.drawImage(images[imageNum], 0, y, hDiff, vDiff, 0, 0, hDiff, vDiff)
    }

    const textFrames = aggregateTextFrames(
      animationContainer.querySelectorAll("[data-frame]")
    )

    const defaultScrollTriggerOpts = {
      start: "top top",
      scrub: true,
      markers: false,
      pin: true,
      anticipatePin: 1,
    }

    const scrollAnimationSpeed = {
      end: () => {
        return `+=${imageHeight / 15}px`
      },
    }

    gsap
      .timeline()
      .to(videoContainer, {
        autoAlpha: 0,
        scrollTrigger: {
          ...defaultScrollTriggerOpts,
          trigger: videoContainer,
          end: "+=100px",
        },
      })
      .to(
        animationContainer,
        {
          autoAlpha: 1,
          scrollTrigger: {
            ...defaultScrollTriggerOpts,
            trigger: animationContainer,
            pin: false,
            end: "+=400px",
          },
        },
        "-=50%"
      )
      .to(
        animationContainerFrameCounter,
        {
          scrollTrigger: {
            ...defaultScrollTriggerOpts,
            ...scrollAnimationSpeed,
            trigger: canvasContainer,
          },
          immediateRender: false,
          num: numImages,
          ease: `steps(${numImages})`,
          onUpdate,
        },
        ">"
      )
      .to(
        animationTextContainer,
        {
          scrollTrigger: {
            ...defaultScrollTriggerOpts,
            ...scrollAnimationSpeed,
            trigger: animationTextContainer,
          },
          ease: `steps(${numImages})`,
        },
        "<"
      )
  }, [])

  useEffect(() => {
    // @ts-ignore ignore missing type declarations
    import("@google/model-viewer").then(() => {
      setModelViewerLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (modelRef.current && modelViewerLoaded) {
      setCanActivateAR(modelRef.current.canActivateAR)
    }
  }, [modelRef, modelViewerLoaded])

  return (
    <div data-testid="backstop-remove">
      <section
        ref={scrollContainerRef}
        className="col-1 grid grid-cols-1 grid-rows-1 overflow-x-hidden"
      >
        <div ref={videoContainerRef} className="relative [grid-area:1/1]">
          <ResponsiveVideo
            className="h-screen"
            config={{
              playsInline: true,
              autoPlay: true,
              loop: true,
              poster: posterHero1080p,
              controls: false,
            }}
            videos={{
              _: {
                sources: [{ source: videoHero360p, type: "video/mp4" }],
                poster: posterHero360p,
              },
              sm: {
                sources: [{ source: videoHero360p, type: "video/mp4" }],
                poster: posterHero360p,
              },
              md: {
                sources: [{ source: videoHero540p, type: "video/mp4" }],
                poster: posterHero540p,
              },
              lg: {
                sources: [{ source: videoHero720p, type: "video/mp4" }],
                poster: posterHero720p,
              },
              xl: {
                sources: [{ source: videoHero720p, type: "video/mp4" }],
                poster: posterHero720p,
              },
              xxl: {
                sources: [{ source: videoHero1080p, type: "video/mp4" }],
                poster: posterHero1080p,
              },
            }}
          />
          <div className="absolute left-1/2 top-[calc(404/1080*100%)] aspect-[314/496] h-[calc(496/1080*100%)] -translate-x-1/2">
            <Logo className="h-full w-full" />
          </div>
          <DoubleArrowIcon className="absolute bottom-container left-1/2 h-[28px] w-[28px] -translate-x-1/2 animate-bounce stroke-current text-white" />
        </div>
        <div
          ref={animationContainerRef}
          className="relative opacity-0 [grid-area:1/1]"
        >
          <canvas
            ref={canvasContainerRef}
            className="h-screen w-full object-cover object-center"
          />
          <div
            ref={animationTextContainerRef}
            className="absolute inset-0 z-10 h-[calc(var(--onefourfive-1vh)*100)] w-screen overflow-x-hidden text-[12vw] text-white sm:text-[5.625vw]"
          >
            <div className="pointer-events-none contents select-none font-condensed font-bold uppercase uppercase leading-[85%]">
              {/* One Four Five animation (_) */}
              <div className="absolute bottom-[4.16vw] left-1/2 flex -translate-x-1/2 flex-row items-center sm:hidden">
                <div className="col-1 grid grid-cols-1 grid-rows-1">
                  <div
                    className={`flex flex-col items-center space-y-3 [grid-area:1/1] ${fadeClassName}`}
                    data-frame="4:18"
                  >
                    <OneIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    <div>One</div>
                  </div>
                  <div
                    className={`flex flex-col items-center space-y-3 [grid-area:1/1] ${fadeClassName}`}
                    data-frame="19:33"
                  >
                    <FourIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    <div>Four</div>
                  </div>
                  <div
                    className={`flex flex-col items-center space-y-3 [grid-area:1/1] ${fadeClassName}`}
                    data-frame="34:48"
                  >
                    <FiveIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    <div>Five</div>
                  </div>
                </div>
              </div>

              {/* One Four Five animation (sm) */}
              <div className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 flex-row items-center sm:flex">
                <div className="flex flex-row items-center space-x-[4vw]">
                  <div className="relative flex w-[8.75vw] flex-row items-center justify-center">
                    <span className="invisible leading-none">&nbsp;</span>
                    <div
                      data-frame="4:12"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      <OneIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    </div>
                    <div
                      data-frame="13:47"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      One
                    </div>
                  </div>
                  <div className="relative flex w-[8.75vw] flex-row items-center justify-center">
                    <span className="invisible leading-none">&nbsp;</span>
                    <div
                      data-frame="17:25"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      <FourIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    </div>
                    <div
                      data-frame="26:47"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      Four
                    </div>
                  </div>
                  <div className="relative flex w-[8.75vw] flex-row items-center justify-center">
                    <span className="invisible leading-none">&nbsp;</span>
                    <div
                      data-frame="30:38"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      <FiveIcon className="h-auto w-[12vw] sm:w-[5.625vw]" />
                    </div>
                    <div
                      data-frame="39:47"
                      className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName}`}
                    >
                      Five
                    </div>
                  </div>
                </div>
              </div>

              {/* Bottleneck animation */}
              <div
                data-frame="220:240"
                className={`absolute left-1/2 top-[20%] -translate-x-1/2 text-center sm:translate-x-[10vw] sm:text-left ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_1",
                  })
                )}
              </div>

              {/* Bottle bottom animation */}
              <div
                data-frame="275:290"
                className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_2",
                  })
                )}
              </div>

              {/* Pure and mixed animation */}
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center sm:hidden">
                <span data-frame="350:385" className={fadeClassName}>
                  {nl2br(
                    intl.formatMessage({
                      id: "page.index.scroll_animation.seq_3",
                    })
                  )}
                </span>
                <br />
                <span data-frame="360:385" className={fadeClassName}>
                  {nl2br(
                    intl.formatMessage({
                      id: "page.index.scroll_animation.seq_4",
                    })
                  )}
                </span>
              </div>
              <div
                data-frame="350:385"
                className={`absolute right-1/2 top-1/2 hidden -translate-x-[10vw] -translate-y-[10vh] sm:block ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_3",
                  })
                )}
              </div>
              <div
                data-frame="360:385"
                className={`absolute left-1/2 top-1/2 hidden translate-x-[10vw] translate-y-[22vh] sm:block ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_4",
                  })
                )}
              </div>

              {/* Daylight animation */}
              <div
                data-frame="420:459"
                className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 sm:hidden ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_5",
                  })
                )}
              </div>
              <div
                data-frame="459:490"
                className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap sm:hidden ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_6",
                  })
                )}
              </div>
              <div
                data-frame="420:480"
                className={`absolute right-1/2 top-1/2 hidden -translate-x-[10vw] -translate-y-1/2 sm:block ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_5",
                  })
                )}
              </div>
              <div
                data-frame="459:490"
                className={`absolute left-1/2 top-1/2 hidden -translate-y-1/2 translate-x-[10vw] whitespace-nowrap sm:block ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_6",
                  })
                )}
              </div>

              {/* Bottle tower animation */}
              <div
                data-frame="540:570"
                className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center ${fadeClassName}`}
              >
                {nl2br(
                  intl.formatMessage({
                    id: "page.index.scroll_animation.seq_7",
                  })
                )}
              </div>
            </div>

            <div
              data-frame="680:702"
              className={`pointer-events-none absolute left-1/2 top-[10%] flex -translate-x-1/2 flex-col items-center space-y-4 text-center sm:left-auto sm:right-1/2 sm:-translate-x-[10vw] sm:translate-y-0 sm:items-start sm:text-left ${fadeClassName}`}
            >
              <div className="pointer-events-none flex select-none flex-col space-y-4 sm:space-y-6">
                <div className="font-condensed text-[16vw] leading-[85%] text-white sm:text-[7.5vw]">
                  {nl2br(
                    intl.formatMessage({
                      id: "page.index.scroll_animation.seq_8",
                    })
                  )}
                </div>
                <div className="hidden font-sans text-base font-normal leading-[120%] sm:block sm:max-w-[30vw]">
                  {nl2br(
                    intl.formatMessage({
                      id: "page.ar.paragraph",
                    })
                  )}
                </div>
              </div>

              <div
                className={`relative flex flex-col items-center justify-center space-y-4 opacity-100 sm:space-y-6 ${
                  canActivateAR !== false ? "block" : "hidden"
                }`}
              >
                {/* @ts-ignore model-viewer is pretty valid */}
                <model-viewer
                  ref={modelRef}
                  src="/ar.glb"
                  reveal="manual"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  camera-controls
                  class="h-[92px] w-[92px] p-[6px] [--poster-color:transparent] [--progress-bar-color:transparent] focus:outline-none focus-visible:outline-none"
                >
                  <button
                    slot="ar-button"
                    className="relative flex h-[80px] w-[80px] items-center justify-center rounded-[50%] bg-razzle-dazzle-rose p-[4px] text-white hover:text-white sm:hidden"
                  >
                    <ARIcon className="h-[51px] w-[51px] shrink-0" />
                  </button>
                  {/* @ts-ignore model-viewer is pretty valid */}
                </model-viewer>
                {isChrome && isIOS ? (
                  <div className="font-sans text-base font-normal leading-[120%]">
                    {nl2br(
                      intl.formatMessage({
                        id: "model_viewer.potential_chrome_bug",
                      })
                    )}
                  </div>
                ) : null}
              </div>

              <div
                className={`relative flex-col items-center space-y-4 sm:items-start sm:space-y-0 ${
                  canActivateAR ? "hidden" : "flex"
                }`}
              >
                <button
                  onClick={toggleARModal}
                  className="relative flex h-[96px] w-[96px] items-center justify-center rounded-[50%] bg-razzle-dazzle-rose p-[4px] text-white hover:text-white"
                >
                  <ARIcon className="h-[65px] w-[65px] shrink-0" />
                </button>
              </div>

              <div className="font-sans text-base font-normal leading-[120%] sm:hidden">
                {nl2br(
                  intl.formatMessage({
                    id: "page.ar.paragraph",
                  })
                )}
              </div>
            </div>
            <Modal
              onClose={toggleARModal}
              qrCode={
                <StaticImage
                  src="../../images/ar-qr-code.png"
                  alt={intl.formatMessage({
                    id: "model_viewer.qr_modal.qr_image_alt",
                  })}
                  className="h-[200px] w-[200px]"
                />
              }
              className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${fadeClassName} ${
                showARModal
                  ? "pointer-events-auto !opacity-100"
                  : "pointer-events-none"
              }`}
            />
          </div>
          <div className="fixed bottom-[4.16vw] right-[4.16vw] z-20">
            <BuyButton />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ScrollingArea
