import React from "react"

type VideoSource = {
  source: string
  type: "video/mp4" | "video/webm"
}

type Props = {
  controls: boolean
  autoPlay: boolean
  loop: boolean
  playsInline: boolean
  poster: string
  className: string
  sources: VideoSource[]
}

const Video: React.FC<Props> = ({
  controls,
  autoPlay,
  loop,
  playsInline,
  poster,
  className,
  sources,
}) => {
  return (
    <video
      controls={controls}
      muted={autoPlay}
      autoPlay={autoPlay && !process.env.BACKSTOP_RUN}
      loop={loop}
      playsInline={playsInline}
      poster={poster}
      preload="none"
      className={className}
      data-testid="backstop-hide"
    >
      {sources.map(({ source, type }) => (
        <source key={type} src={source} type={type} />
      ))}
    </video>
  )
}

export default Video
