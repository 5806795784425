import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/layout/heading"
import { StaticImage } from "gatsby-plugin-image"
import ScrollingArea from "../components/index/scrolling-area"
import Parallax from "../components/parallax"

const IndexPage: React.FC = () => {
  const intl = useIntl()

  return (
    <Layout ignoreFooterSpacing>
      <SEO title={intl.formatMessage({ id: "page.index.title" })} />

      <ScrollingArea />

      <div className="mt-[8.32vw]">
        {/* Marketing Section "Heritage Meets Future" */}
        <section className="grid h-[158.333vw] w-full min-w-[320px] auto-cols-[1fr] grid-cols-[1fr_3fr_5fr_2fr_1fr] grid-rows-[2fr_6fr_2fr_7fr] gap-0 py-[8.32vw] sm:h-[54.17vw] sm:grid-cols-[2fr_7fr_2fr_2fr_9fr_2fr] sm:grid-rows-[2fr_2fr_1fr_4fr]">
          <StaticImage
            src="../images/heritage-meets-future.jpg"
            alt={intl.formatMessage({
              id: "page.index.heritage_meets_future.img_alt",
            })}
            className="col-start-2 col-end-4 row-start-1 row-end-3 sm:col-end-4 sm:row-end-5"
            imgClassName="object-left"
          />

          <Parallax className="z-10 col-start-3 col-end-5 row-start-2 row-end-3 translate-y-[0] sm:col-end-6">
            <Heading importance="h4" className="text-[16vw] sm:text-[7.5vw]">
              {intl.formatMessage({
                id: "page.index.heritage_meets_future.title",
              })}
            </Heading>
          </Parallax>

          <p className="z-10 col-start-2 col-end-5 row-start-4 row-end-5 sm:col-start-5 sm:col-end-6">
            {intl.formatMessage({
              id: "page.index.heritage_meets_future.text",
            })}
          </p>
        </section>

        {/* Marketing Section "Essence of Quality" */}
        <section className="grid h-[275vw] w-full min-w-[320px] auto-cols-[1fr] grid-cols-[1fr_1fr_9fr_1fr] grid-rows-[2fr_7fr_2fr_4fr_2fr_6fr_1fr_3fr_1fr_6fr] gap-0 py-[8.32vw] sm:h-[83.33vw] sm:grid-cols-[2fr_7fr_2fr_8fr_3fr_2fr] sm:grid-rows-[2fr_2fr_2fr_3fr_2fr_1fr_1fr_3fr_auto_1fr_auto]">
          <StaticImage
            src="../images/quality-1.jpg"
            alt={intl.formatMessage({ id: "page.index.quality.img_1_alt" })}
            className="col-start-1 col-end-4 row-start-1 row-end-4 sm:col-end-5 sm:row-end-6"
          />

          <Parallax className="col-start-3 col-end-5 row-start-3 row-end-5 overflow-hidden sm:col-start-4 sm:col-end-6 sm:row-start-5 sm:row-end-9">
            <StaticImage
              src="../images/quality-2.jpg"
              alt={intl.formatMessage({ id: "page.index.quality.img_2_alt" })}
              className="h-full w-full"
            />
          </Parallax>

          <p className="z-10 col-start-2 col-end-4 row-start-6 row-end-7 sm:col-end-3 sm:row-start-7 sm:row-end-10">
            {intl.formatMessage({ id: "page.index.quality.text_1" })}
          </p>

          <Parallax className="z-10 col-start-5 col-end-7 row-start-3 row-end-5 mt-2 hidden px-11 sm:block">
            <StaticImage
              src="../images/dlg-medal-gold-2022.png"
              alt="DLG International Award 2022 in Gold"
              className="h-auto"
              objectFit="contain"
              objectPosition="top"
            />
          </Parallax>
          <div className="col-start-2 col-end-4 row-start-8 row-end-9 sm:hidden">
            <StaticImage
              src="../images/dlg-medal-gold-2022.png"
              alt="DLG International Award 2022 in Gold"
              className="h-[140px]"
              objectFit="contain"
              objectPosition="top"
            />
          </div>

          <Parallax className="z-10 col-start-3 col-end-5 row-start-2 row-end-3 sm:col-start-4 sm:col-end-7">
            <Heading importance="h4" className="text-[16vw] sm:text-[7.5vw]">
              {intl.formatMessage({ id: "page.index.quality.title" })}
            </Heading>
          </Parallax>

          <p className="z-10 col-start-2 col-end-4 row-start-10 row-end-11 sm:col-start-4 sm:col-end-6 sm:row-start-11">
            {intl.formatMessage({ id: "page.index.quality.text_2" })}
          </p>
        </section>

        {/* Marketing Section "Limited Edition" */}
        <section className="grid w-full min-w-[320px] auto-cols-[1fr] grid-cols-[1fr_8fr_2fr_1fr] grid-rows-[auto_1fr_8fr] gap-0 py-[8.32vw] sm:grid-cols-[2fr_7fr_2fr_8fr_3fr_2fr] sm:grid-rows-[auto]">
          <StaticImage
            src="../images/limited-edition.jpg"
            alt={intl.formatMessage({
              id: "page.index.limited_edition.img_alt",
            })}
            className="col-start-2 col-end-3 row-start-1 row-end-1 max-h-[50vh] self-end sm:col-end-3 sm:max-h-[30vw]"
            objectFit="contain"
            imgClassName="object-left sm:object-center"
          />

          <div className="col-start-2 col-end-4 row-start-3 row-end-3 flex flex-col justify-end space-y-4 sm:col-start-4 sm:col-end-5 sm:row-start-1 sm:row-end-1">
            <Parallax>
              <Heading
                importance="h4"
                className="w-min text-[16vw] sm:text-[7.5vw]"
              >
                {intl.formatMessage({ id: "page.index.limited_edition.title" })}
              </Heading>
            </Parallax>

            <p>
              {intl.formatMessage({ id: "page.index.limited_edition.text" })}
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
