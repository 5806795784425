import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

const BuyButton: React.FC = () => {
  const intl = useIntl()

  return (
    <div>
      <a
        href="https://www.145vodka.com"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center justify-center rounded-full bg-razzle-dazzle-rose px-[50px] pb-[8px] pt-[12px] font-condensed text-[24px] font-bold uppercase leading-none text-white hover:bg-[#ec68cf] hover:text-white"
      >
        {intl.formatMessage({ id: "page.index.buy" })}
      </a>
    </div>
  )
}

export default BuyButton
