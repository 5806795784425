import React from "react"
import Video from "./video"

type VideoConfig = {
  poster: string
  autoPlay: boolean
  loop: boolean
  playsInline: boolean
  controls: boolean
}

type VideoSource = {
  source: string
  type: "video/mp4" | "video/webm"
}

type Video = {
  sources: VideoSource[]
  poster?: string
  autoPlay?: boolean
  loop?: boolean
  playsInline?: boolean
  controls?: boolean
}

type Videos = {
  _: Video
  sm: Video
  md: Video
  lg: Video
  xl: Video
  xxl: Video
}

type Props = {
  config: VideoConfig
  videos: Videos
  className?: string
}

const ResponsiveVideo: React.FC<Props> = ({ config, videos, className }) => {
  return (
    <>
      {Object.keys(videos).map(key => {
        const {
          autoPlay,
          controls,
          loop,
          playsInline,
          poster,
          sources,
        }: Video = videos[key as keyof Videos]

        let visibilityClassName = ""
        switch (key as keyof Videos) {
          case "_":
            visibilityClassName = "block sm:hidden"
            break
          case "sm":
            visibilityClassName = "hidden sm:block md:hidden"
            break
          case "md":
            visibilityClassName = "hidden md:block lg:hidden"
            break
          case "lg":
            visibilityClassName = "hidden lg:block xl:hidden"
            break
          case "xl":
            visibilityClassName = "hidden xl:block xxl:hidden"
            break
          case "xxl":
            visibilityClassName = "hidden xxl:block"
            break
        }

        return (
          <Video
            key={key}
            controls={controls || config.controls}
            autoPlay={autoPlay || config.autoPlay}
            loop={loop || config.loop}
            playsInline={playsInline || config.playsInline}
            poster={poster || config.poster}
            className={`w-full max-w-full object-cover object-center ${visibilityClassName} ${className}`}
            sources={sources}
          />
        )
      })}
    </>
  )
}

export default ResponsiveVideo
